<script setup lang="ts">
import smLogo from '@/assets/solar-materials-logo.svg';
import IconLock from '~icons/bxs/lock-alt';
import IconLoading from '~icons/eos-icons/loading';
import IconWarn from '~icons/fe/warning';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/store';

const apiBaseUrl = import.meta.env.VITE_APIURL;
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

useHead({
  title: 'Login | Panel Manager',
});

const autologout = ref(false);

if (route.query.autologout) {
  router.push(route.path);
  autologout.value = true;
}

const email = ref('');
const password = ref('');
const loading = ref(false);

const errorOpen = ref(false);
const errorMsg = ref('');
const closeError = () => {
  errorOpen.value = false;
};

const submitLogin = async () => {
  if (typeof window !== undefined) {
    loading.value = true;

    const res = await axios
      .post(`${apiBaseUrl}auth/local`, {
        identifier: email.value,
        password: password.value,
      })
      .catch(error => {
        if (error?.response) {
          errorMsg.value = errorMsg.value = 'Login failed: ' + error?.response?.data?.error?.message;
        } else if (error.request) {
          errorMsg.value = 'No response from server';
        } else {
          errorMsg.value = error?.message;
        }
        password.value = '';
        loading.value = false;
        errorOpen.value = true;
      });

    loading.value = false;

    const { jwt, user } = res?.data;

    window.sessionStorage.setItem('jwt', jwt);
    window.sessionStorage.setItem('userData', JSON.stringify(user));
    authStore.$patch({
      authenticated: true,
    });
    router.push('/');
  }
};
</script>
<template>
  <div class="max-w-md w-full">
    <div v-if="autologout"
      class="px-6 py-4 rounded-md bg-slate-50 border border-slate-600 mb-4 text-center font-medium text-slate-700 flex items-center justify-center gap-2">
      <IconWarn></IconWarn> You have been logged out automatically
    </div>
    <div class="space-y-12 p-6 bg-white rounded-md border border-slate-200">
      <div>
        <img class="mx-auto h-12 w-auto" :src="smLogo" alt="Solar Materials" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-slate-900">Login</h2>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="submitLogin()">
        <div class="-space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" name="email" v-model="email" type="email" autocomplete="email" required
              class="input-text" placeholder="Email address" />
          </div>
        </div>
        <div class="-space-y-px">
          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" name="password" v-model="password" type="password" autocomplete="current-password"
              required class="input-text" placeholder="Password" />
          </div>
        </div>
        <div>
          <button type="submit" class="button w-full">
            <span class="button-icon">
              <IconLoading v-if="loading" class="scale-125"></IconLoading>
              <IconLock v-else></IconLock>
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
    <Teleport to="body">
      <DialogPopup :open="errorOpen" @close="closeError()" :appearance="'error'" :title="'Error'">{{ errorMsg }}
      </DialogPopup>
    </Teleport>
  </div>
</template>

<route lang="yaml">
name: login
meta:
  layout: login
</route>
